import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import App from './App';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <CssBaseline />
    <Router>
      <Routes>
        {/* Home Route */}
        <Route path="/" element={<App />} />

        {/* Data Deletion Policy Route */}
        <Route path="/policy/account/data/deletion" element={<App />} />
      </Routes>
    </Router>
  </React.StrictMode>
);

