import React from 'react';
import { Box, Typography, Container, Paper, Divider } from '@mui/material';

function App() {
  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ padding: 4, mt: 5 }}>
        <Typography variant="h4" align="center" gutterBottom>
          User Data Deletion
        </Typography>

        <Typography variant="body1" paragraph>
          On expiration of the retention period, termination of Customer’s Agreement, or earlier on Customer’s request
          to GiftTime’s Data Privacy Contact, GiftTime shall (at Customer's election) promptly destroy or return to
          Customer all Personal Data in its possession or control, including any Personal Data subcontracted to a
          third party for Processing. This requirement shall not apply to the extent that GiftTime is required by law
          to retain some or all of the Personal Data, in which event GiftTime shall isolate and protect the Personal
          Data from any further Processing except to the extent required by such law.
        </Typography>

        <Divider sx={{ marginY: 3}} />

        <Typography variant="h6">
          How to Delete Your Account
        </Typography>

        <Typography variant="body2" paragraph>
          To delete your account, you may go to the "Account" section in the GiftTime app and tap the "Delete Account" button.
        </Typography>


        <Divider sx={{ marginY: 3 }} />

        <Box textAlign="center" mt={3}>
          <Typography variant="body2" fontStyle="italic">
            Thank you from the GiftTime team
          </Typography>
          <Typography variant="body2" color="textSecondary">
            We are sorry to see you go.
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
}

export default App;
